import { lazy, Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Loading from "./common/UI/Loading/Loading";


const Authentication = lazy(() => import("./pages/Auth/Auth"));
const Login = lazy(() => import("./pages/Auth/pages/Login/Login"));
const Register = lazy(() => import("./pages/Auth/pages/Register/Register"));
const Forgot = lazy(() => import("./pages/Auth/pages/Forgot/Forgot"));
const Otp = lazy(() => import("./pages/Auth/pages/Otp/Otp"));
const Reset = lazy(() => import("./pages/Auth/pages/Reset/Reset"));
const Verify = lazy(() => import("./pages/Auth/pages/Verify/Verify"));

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Discover = lazy(() => import("./pages/Dashboard/Discover/Discover"));
const Overview = lazy(() => import("./pages/Dashboard/Overview/Overview"));
const Packages = lazy(() => import("./pages/Dashboard/Packages/Package"));
const PackagePlan = lazy(
  () => import("./pages/Dashboard/PackagePlan/PackagePlan")
);
const Checkout = lazy(() => import("./pages/Dashboard/Checkout/Checkout"));
const PaymentResponse = lazy(
  () =>
    import("./pages/Dashboard/Checkout/pages/PaymentResponse/PaymentResponse")
);
const Receipt = lazy(
  () => import("./pages/Dashboard/Checkout/pages/Receipt/Receipt")
);

const CryptoNews =  lazy(()=> import("./pages/CryptoNews/CryptoNews"));
const AboutUs  =  lazy(()=> import("./pages/AboutUs/AboutUs"));
const AffiliateMarketing = lazy(()=> import("./pages/AffiliateMarketing/AffiliateMarketing"))

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Suspense fallback={<Loading />}>
        <Authentication />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: "register",
        element: (
          <Suspense fallback={<Loading />}>
            <Register />
          </Suspense>
        ),
      },
      {
        path: "forgot",
        element: (
          <Suspense fallback={<Loading />}>
            <Forgot />
          </Suspense>
        ),
      },
      {
        path: "otp/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <Otp />
          </Suspense>
        ),
      },
      {
        path: "reset",
        element: (
          <Suspense fallback={<Loading />}>
            <Reset />
          </Suspense>
        ),
      },
      {
        path: "verify/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <Verify />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "",
    element: (
      <Suspense fallback={<Loading />}>
        <Dashboard />
      </Suspense>
    ),
    children: [
      {
        path: "overview",
        element: (
          <Suspense fallback={<Loading />}>
            <Overview />
          </Suspense>
        ),
      },
      {
        path: "discover",
        element: (
          <Suspense fallback={<Loading />}>
            <Discover />
          </Suspense>
        ),
      },
      {
        path: "autotrade",
        element: (
          <Suspense fallback={<Loading />}>
            <Packages />
          </Suspense>
        ),
      },
      {
        path: "/autotrade/plan/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <PackagePlan />
          </Suspense>
        ),
      },
      {
        path: "/checkout/:type/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <Checkout />
          </Suspense>
        ),
      },
      {
        path: "payment-status/:id/:id2",
        element: (
          <Suspense fallback={<Loading />}>
            <PaymentResponse />
          </Suspense>
        ),
      },
      {
        path: "receipt/:id",
        element: (
          <Suspense fallback={<Loading />}>
            <Receipt />
          </Suspense>
        ),
      },
      {
        path: "crypto-news",
        element: (
          <Suspense fallback={<Loading />}>
            <CryptoNews />
          </Suspense>
        ),
      },
      {
        path: "about-us",
        element: (
          <Suspense fallback={<Loading />}>
            <AboutUs />
          </Suspense>
        ),
      },
      {
        path: "affiliate-marketing",
        element: (
          <Suspense fallback={<Loading />}>
            <AffiliateMarketing />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

export default router;
